import { createSlice } from '@reduxjs/toolkit';

import { ClientsApi } from '../../../client_http_api';
import http from '../../../shared_client_utils/http';

const initialState = { byId: {}, ids: [] };

const clients = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    completeLoadClients: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((prevValue, currValue) => ({
        ...prevValue, [currValue.id]: currValue,
      }), {});

      return { ...state, byId, ids };
    },
    completeLoadSortedClients: (state, { payload }) => {
      const ids = payload.clients.map(({ id }) => id);
      const byId = payload.clients.reduce((result, currValue) => ({
        ...result, [currValue.id]: currValue,
      }), {});

      return { ...state, byId, ids };
    },
    completeLoadClient: (state, { payload }) => {
      let ids = [...state.ids];
      if (ids.indexOf(payload.id) === -1) {
        ids = [...ids, payload.id];
      }

      return {
        ...state,
        ids,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
      };
    },
    completeCreateClient: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
        ids: [...state.ids, payload.id],
      };
    },
    completeUpdateClient: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
      };
    },
  },
});

export const {
  completeLoadClients,
  completeLoadSortedClients,
  completeLoadClient,
  completeCreateClient,
  completeUpdateClient,
} = clients.actions;

export default clients.reducer;

export const loadClients = () => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/clients`, config);

  return dispatch(completeLoadClients(response));
};

export const loadSortedClients = options => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/clients/get-sorted`, config);

  return dispatch(completeLoadSortedClients(response));
};

export const loadClient = (id, isView) => async (dispatch, getState) => {
  const { auth } = getState();
  const response = await ClientsApi.fetchClient(id, auth, isView);

  return dispatch(completeLoadClient(response));
};

export const createClient = (data, options) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/clients/`, config);

  return dispatch(completeCreateClient(response));
};

export const updateClient = (id, data, options) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/clients/${id}`, config);

  return dispatch(completeUpdateClient(response));
};
