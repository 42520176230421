import axios from 'axios';
import http from '../shared_client_utils/http';

export default {
  fetchClient: (id, auth, isView) => {
    const { token } = auth;
    const config = {
      ...(isView ? { params: { isView: 'ok' } } : {}),
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/clients/${id}`, config);
  },
  fetchOutstandingBalance: (id, auth) => {
    const { token } = auth;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/clients/${id}/get-outstanding-balance`, config);
  },
  mergeClients: (oldClientId, newClientId, auth, options) => {
    const { token } = auth;
    const config = {
      params: options,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.put(`${window.baseApiPath}/clients/${oldClientId}/merge/${newClientId}`, config);
  },
  calculateClientsReport: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.post(`${window.baseApiPath}/clients/report-for-analytics`, config);
  },
  sendUpdatedIntakeFormEmail: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.post(`${window.baseApiPath}/clients/send-update-intake-email`, config);
  },
  exportClientsReportInCSV: (options, auth) => {
    const { token } = auth;

    return axios.get(`${window.baseApiPath}/clients/export-clients-report-in-csv`, {
      params: options,
      headers: {
        Accept: 'text/csv',
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob',
    });
  },
  exportClientsReportInExcel: (options, auth) => {
    const { token } = auth;

    return axios.get(`${window.baseApiPath}/clients/report/export-clients-report-in-excel`, {
      params: options,
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob',
    });
  },
};
