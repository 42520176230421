import { createSlice } from '@reduxjs/toolkit';

import http from '../../../shared_client_utils/http';

const initialState = { byId: {}, ids: [], isCritical: 0, currentDate: null};

const appointments = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    completeLoadSortableAppointments: (state, { payload }) => {
      const ids = payload.appointments.map(({ id }) => id);
      const byId = payload.appointments.reduce((prevValue, currValue) => ({
        ...prevValue, [currValue.id]: currValue,
      }), {});

      return { ...state, byId, ids };
    },
    completeLoadAppointment: (state, { payload }) => {
      let ids = [...state.ids];
      if (ids.indexOf(payload.id) === -1) {
        ids = [...ids, payload.id];
      }

      return {
        ...state,
        ids,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
      };
    },
    completeCreateAppointment: (state, { payload }) => ({
      ...state,
      byId: {
        ...state.byId,
        [payload.id]: payload,
      },
      ids: [...state.ids, payload.id],
    }),
    completeUpdateAppointment: (state, { payload }) => ({
      ...state,
      byId: {
        ...state.byId,
        [payload.id]: payload,
      },
    }),
    completeDeleteAppointment: (state, { payload }) => {
      const { [payload]: deletedAppointment, ...byId } = state.byId;
      const ids = state.ids.filter(id => id !== payload);

      return { ...state, byId, ids };
    },
    criticalStatus: (state, {payload: { isCritical } }) => {
      return { ...state, isCritical };
    },
  },
});

export const {
  completeLoadSortableAppointments,
  completeLoadAppointment,
  completeCreateAppointment,
  completeUpdateAppointment,
  completeDeleteAppointment,
  criticalStatus
} = appointments.actions;

export default appointments.reducer;

export const loadSortableAppointmentsForClient = (options) => {
  return async (dispatch, getState) => {
    const { auth: { token }} = getState();
    const config = {
      params: options,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    const response = await http.get(`${window.baseApiPath}/appointments/get-for-client`, config);

    return dispatch(completeLoadSortableAppointments(response));
  };
};

export const loadSortableAppointmentsForCustomer = (options) => async (dispatch) => {
  const response = await http.get(
    `${window.baseApiPath}/appointments/get-sortable-for-customer`,
    { params: options },
  );

  return dispatch(completeLoadSortableAppointments(response));
};

export const loadAppointmentsForDashboard = (options) => async (
  dispatch,
  getState
) => {
  const { auth: { token }} = getState();
  const config = {
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(
    `${window.baseApiPath}/appointments/get-for-dashboard`,
    config,
  );

  return dispatch(completeLoadSortableAppointments(response));
};

export const loadAppointment = (id) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/appointments/${id}`, config);

  return dispatch(completeLoadAppointment(response));
};

export const loadAppointmentForClient = (appointmentId) => {
  return async (dispatch, getState) => {
    const { auth: { token }} = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    const response = await http.get(
      `${window.baseApiPath}/appointments/${appointmentId}/get-for-client`,
      config,
    );

    return dispatch(completeLoadAppointment(response));
  };
};

export const createAppointmentWithExtras = (data, options) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/appointments/create-with-extras`, config);

  return dispatch(completeCreateAppointment(response));
};

export const updateAppointment = (id, data, options) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/appointments/${id}`, config);

  return dispatch(completeUpdateAppointment(response));
};

export const updateAppointmentWithExtras = (id, data, options) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/appointments/${id}/update-with-extras`, config);

  return dispatch(completeUpdateAppointment(response));
};

export const cancelAppointment = (id, data, options) => {
  return async (dispatch, getState) => {
    const { auth: { token }} = getState();
    const config = {
      data,
      params: options,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    const response = await http.put(`${window.baseApiPath}/appointments/${id}/cancel`, config);

    return dispatch(completeUpdateAppointment(response));
  };
};

export const deleteAppointment = appointmentId => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data: { appointmentId },
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  await http.delete(`${window.baseApiPath}/appointments/`, config);

  return dispatch(completeDeleteAppointment(appointmentId));
};

export const setCriticalStatus = (isCritical) => async (dispatch) => {
  return dispatch(criticalStatus({isCritical}));
};

export const updateNotesOnly = (id, data, options) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/appointments/${id}/notes-only-update`, config);

  return dispatch(completeUpdateAppointment(response));
};