import moment from 'moment-timezone';
import { isEmpty } from 'lodash';

export const prepareOptions = object => Object.entries(object).map(([, element]) => element);

const isFileTooBig = (f, size) => Math.round(f.size / 1024 / 1024) >= size;

export const findFile = (files) => files && files.length ? files[0] : null;

export const validateFile = (file, size = 5) => {
  if (!file) {
    return { isValid: false, message: 'File is missing!' };
  }
  if (isFileTooBig(file, size)) {
    const fileSize = Math.round((file.size * 100) / 1024 / 1024) / 100;
    return {
      isValid: false,
      message: `Allowed size is ${size}Mb but uploaded file size is ${fileSize}Mb!`,
    };
  }
  return { isValid: true, message: '' };
};

export const isCheckboxInSelected = data => id => data.indexOf(id) !== -1;

export const makeOption = (record, options = {}) => {
  const { labelColumn = 'name', valueColumn = 'id' } = options;

  return {
    ...record,
    label: record[labelColumn],
    value: record[valueColumn],
  };
};

export const makeCustomOption = (record, options = {}) => {
  const { labelColumn = 'name', valueColumn = 'id' } = options;
  return {
    ...record,
    label: record.deletedAt && !isEmpty(record.deletedAt) ? `${record[labelColumn]} *` : record[labelColumn],
    value: record[valueColumn],
  };
};

export const makeOptionWithField = (options) => {
  return (record) => makeOption(record, options);
};

export const makeCustomOptionWithField = (options) => {
  return (record) => makeCustomOption(record, options);
};

export const timezoneOptions = moment.tz.names().map(tzName => ({
  label: tzName,
  value: tzName,
}));

export const makeOptionFromArray = ([value, label]) => ({ label, value });

export const orderCategories = (categories) => {
  const generalCategory = categories.find(({ name }) => name === 'General');
  const restCategories = categories.filter(({ name }) => name !== 'General')
  return [generalCategory, ...restCategories];
}

const getNoCategoryOption = (generalId) => ({
  label: "No category",
  value: generalId,
});
export const orderCategoriesWithNoCategory = (categories) => {
  const generalCategory = categories.find(({ name }) => name === 'General');
  const filteredCategories = categories.filter(({ name }) => name !== 'General');
  return [
    getNoCategoryOption(generalCategory.id),
    ...filteredCategories,
  ];
};

const notApplicableTax = { label: 'Not applicable', value: '' };
export const orderTaxesWithNoApplicable = (taxes) => {
  return [
    notApplicableTax,
    ...taxes,
  ];
};

export const addAllOptionToStaff = (staff) => {
  const allOption = { label: 'All', value: '' };
  return [
    allOption,
    ...staff,
  ];
};

export const allSmthOption = (name) => ({ label: `All ${name}`, value: '' });

export const addAllSmthOption = (records, name) => {
  const allOption = allSmthOption(name);

  return [
    allOption,
    ...records,
  ];
};

export const validateObjectByMap = (object, errorsMap, options = {}) => {
  const errors = Object.keys(object).reduce((acc, key) => {
    const value = object[key];
    const fieldObject = errorsMap[key];

    if (!fieldObject || fieldObject.isValid(value, options)) {
      return acc;
    }

    return { ...acc, [key]: fieldObject.message || true };
  }, {});

  if (isEmpty(errors)) {
    return { isValid: true, errors };
  }
  return { isValid: false, errors };
};

export const mapToArray = mapObject => Array.from(mapObject);

export const updateMapWithNewDirection = (mapObject, column) => {
  const newMap = new Map(mapObject);
  const newDirection = newMap.get(column) === 'asc' ? 'desc' : 'asc';
  const newMapItem = [column, newDirection];

  newMap.delete(column);
  const restMapObjectAsArray = mapToArray(newMap);
  const newMapArray = [newMapItem].concat(restMapObjectAsArray);

  return new Map(newMapArray);
};

export const setInitialStatus = entity => ({ ...entity, status: 'initial' });
export const setNewStatus = entity => ({ ...entity, status: 'new' });
export const setDeletedStatus = entity => ({ ...entity, status: 'deleted' });

export const getInitials = (firstName, lastName) => `${firstName[0]}${lastName[0]}`;

export const getValueForFormData = (object, prop) => {
  const value = object[prop];
  return (value === null) ? '' : value;
};

export const createOptionsOfNumbers = (num) => {
  return Array.from(Array(num)).map((empty, index) => {
    const value = index + 1;
    const label = value.toString();

    return makeOptionFromArray([value, label]);
  });
};

export const pwdCheckMeter = (pwdValidate) => {
  let pwdCheck = 0;
  if (pwdValidate?.length < 8) return { strength: 0, val: "min 8 characters" };
  const validateRegex = ["[A-Z]", "[a-z]", "[0-9]", "\\W"];
  validateRegex.forEach((regex) => {
    if (new RegExp(regex).test(pwdValidate)) {
      pwdCheck += 1;
    }
  });
  switch (pwdCheck) {
    case 1:
      return {
        strength: 1,
        val: "very weak",
      };
    case 2:
      return {
        strength: 2,
        val: "weak",
      };
    case 3:
      return {
        strength: 3,
        val: "good",
      };
    case 4:
      return {
        strength: 4,
        val: "strong",
      };
    default:
      return {
        strength: 0,
        val: "",
      };
  }
};
