/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

import http from "../../../shared_client_utils/http";

const initialState = {
  token: '',
  userId: null,
  currentStaff: {},
  loading: false,
  isSessionIdle: false,
  referrer: null,
};

const completeProcess = (state, action) => {
  const { token, userId, currentStaff } = action.payload;

  return {
    ...state,
    token,
    userId,
    currentStaff,
    loading: false,
    isSessionIdle: false,
    referrer: null,
  };
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    completeSettingStaffPassword: completeProcess,
    completeRegistration: completeProcess,
    completeUserLogin: completeProcess,
    completeResetPassword: completeProcess,
    completeConfirmingEmail: completeProcess,
    logout: (state, action) => {
      return {
        ...state,
        token: '',
        userId: null,
        isSessionIdle: false,
        referrer: null,
      };
    },
    idleSession: (state, action) => {
      return {
        ...state,
        referrer: action.payload,
        isSessionIdle: true,
      };
    },
    completeLoadStaffAccess: (state, { payload }) => {
      return {
        ...state,
        currentStaff: { ...state.currentStaff, access: payload },
      };
    },
  },
});

export const {
  completeSettingStaffPassword,
  completeRegistration,
  completeUserLogin,
  completeResetPassword,
  completeConfirmingEmail,
  completeLoadStaffAccess,
  logout,
  idleSession,
} = auth.actions;

export default auth.reducer;

export const registerUser = (data) => async () => {
  const response = await http.post(`${window.baseApiPath}/accounts`, { data });
  return response;
};

export const setStaffPassword = (password, confirmPassword, token) => async (dispatch) => {
  const config = {
    data: { password, confirmPassword, token },
  };
  const response = await http.put(
    `${window.baseApiPath}/accounts/set-staff-password`,
    config,
  );

  return dispatch(completeSettingStaffPassword(response));
};

export const completeBusinessRegistration = (token) => async (dispatch) => {
  const config = { params: { token } };
  const response = await http.post(
    `${window.baseApiPath}/accounts/complete-business-registration`,
    config,
  );

  return dispatch(completeRegistration(response));
};

export const loginUser = (data) => async (dispatch) => {
  const response = await http.post(`${window.baseApiPath}/accounts/login-user`, { data });

  return dispatch(completeUserLogin(response));
};

export const forgotPassword = email => (dispatch) => {
  return http.put(`${window.baseApiPath}/accounts/forgot`, { data: { email } });
};

export const resetPassword = (password, confirmPassword, token) => async (dispatch) => {
  const config = { data: { password, confirmPassword, token } };
  const response = await http.put(`${window.baseApiPath}/accounts/reset`, config);

  return dispatch(completeResetPassword(response));
};

export const confirmEmail = (token) => async (dispatch) => {
  const config = { params: { token } };
  const response = await http.put(`${window.baseApiPath}/accounts/confirm-email`, config);

  return dispatch(completeConfirmingEmail(response));
};

export const checkSession = () => async (dispatch, getState) => {
  try {
    const { auth: { token } } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    await http.get(`${window.baseApiPath}/accounts/check-session`, config);
  } catch (error) {
    // Assume failed auth
    return dispatch(logout());
  }

  return 'Ok';
};

export const deactivateSession = referrer => (dispatch) => {
  dispatch(idleSession(referrer));
};

export const loadStaffAccess = () => async (dispatch, getState) => {
  const { auth: { token, currentStaff } } = getState();
  const config = {
    params: { staffId: currentStaff.id },
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/staff/get-staff-access`, config);

  return dispatch(completeLoadStaffAccess(response));
};

export const stripeSessionDataUpdate = (data) => async () => {
  const response = await http.post(`${window.baseApiPath}/accounts/sessionDataUpdate`, { data });
  return response;
};

export const removeStripeCustomer = (data) => async () => {
  const response = await http.post(`${window.baseApiPath}/accounts/removeStripeCustomer`, { data });
  return response;
};

export const setLogoutActivity = (options = {}) => async (dispatch, getState) => {
  const { auth: { userId, currentStaff }, business: { id } } = getState();
  const { description, isOnIdle, isOnSession } = options;
  const data = {
    userId,
    currentStaff,
    businessId: id,
    ...(description ? { description } : {}),
    ...(isOnIdle ? { isOnIdle } : {}),
    ...(isOnSession ? { isOnSession } : {}),
  };
  http.post(`${window.baseApiPath}/accounts/set-logout-activity`, { data });
  return true;
};
