/* eslint-disable import/no-unresolved */
import { createSlice } from '@reduxjs/toolkit';

import http from '../shared_client_utils/http';

const initialState = {
  name: '',
  category: '',
  type: '',
  size: '',
  website: '',
  country: '',
  currency: '',
  timezone: '',
  dateFormat: '',
  timeFormat: '',
  logo: '',
};

const businesses = createSlice({
  name: 'businesses',
  initialState,
  reducers: {
    completeLoadBusiness: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    completeBusinessGetStarted: (state, { payload }) => {
      return {
        ...state,
        getStarted: payload,
      };
    }
  },
});

export const {
  completeLoadBusiness,
  completeBusinessGetStarted
} = businesses.actions;

export default businesses.reducer;

export const loadBusiness = (isView = 'no') => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    params: { isView },
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/businesses/`, config);

  return dispatch(completeLoadBusiness(response));
};

export const loadBusinessToDashboard = data => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/businesses-to-dashboard`, config);

  return dispatch(completeLoadBusiness(response));
};

export const loadBusinessBySlugForBooking = slug => async (dispatch) => {
  const config = {
    params: { slug },
  };
  const response = await http.get(
    `${window.baseApiPath}/businesses/get-by-slug-for-booking`,
    config,
  );

  return dispatch(completeLoadBusiness(response));
};

export const loadBusinessForCustomer = id => async (dispatch) => {
  const response = await http.get(`${window.baseApiPath}/businesses/${id}/get-for-customer`);

  return dispatch(completeLoadBusiness(response));
};

export const loadBusinessByIdForBooking = (businessId) => async (dispatch) => {
  const config = {
    params: { businessId },
  };
  const response = await http.get(
    `${window.baseApiPath}/businesses/get-by-id-for-booking`,
    config,
  );

  return dispatch(completeLoadBusiness(response));
};

export const loadBusinessForCustomerAccess = slug => async (dispatch) => {
  const config = {
    params: { slug },
  };
  const response = await http.get(
    `${window.baseApiPath}/businesses/get-for-customer-access`,
    config,
  );

  return dispatch(completeLoadBusiness(response));
};

export const loadBusinessForOnlinePayment = slug => async (dispatch) => {
  const config = {
    params: { slug },
  };
  const response = await http.get(
    `${window.baseApiPath}/businesses/get-for-online-payment`,
    config,
  );

  return dispatch(completeLoadBusiness(response));
};

export const saveBusiness = data => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/businesses`, config);

  return dispatch(completeLoadBusiness(response));
};

export const saveBillingDetails = (data) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/create-billing-details`, config);

  return dispatch(completeLoadBusiness(response));
};

export const readBillingDetails = (businessId) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };

  const response = await http.get(
    `${window.baseApiPath}/get-billing-details/${businessId}`, config,
  );

  return dispatch(completeLoadBusiness(response));
};

export const loadBusinessByVerify = (data) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/load-verify`, config);

  return dispatch(completeLoadBusiness(response));
};

export const loadBusinessBySlugForVendOfGift = slug => async (dispatch) => {
  const config = {
    params: { slug },
  };
  const response = await http.get(
    `${window.baseApiPath}/businesses/get-by-slug-for-vend-of-gift`,
    config,
  );

  return dispatch(completeLoadBusiness(response));
};

export const loadGetStartedStep = (userId, isClear) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };

  let response = [];
  if(!isClear) {
    response = await http.get(`${window.baseApiPath}/get-started/${userId}`, config);
  }
  return dispatch(completeBusinessGetStarted(response));
};
