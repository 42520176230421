import { createSlice } from '@reduxjs/toolkit';

import http from '../../../shared_client_utils/http';

const initialState = { byId: {}, ids: [], isLogoutActivitySent: false };

const usersActivity = createSlice({
  name: 'usersActivities',
  initialState,
  reducers: {
    completeLoadSortedActivity: (state, { payload }) => {
      const ids = payload.activities.map(({ id }) => id);
      const byId = payload.activities.reduce((result, currValue) => ({
        ...result, [currValue.id]: currValue,
      }), {});

      return { ...state, byId, ids };
    },
    completeSentLogout: (state) => {
      return { ...state, isLogoutActivitySent: true };
    },
  },
});

export const {
  completeLoadSortedActivity,
  completeSentLogout
} = usersActivity.actions;

export default usersActivity.reducer;

export const loadUserActivity = data => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/userActivities/get-sorted-activity`, config);

  return dispatch(completeLoadSortedActivity(response));
};

export const clientFieldViewActivity = data => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/userActivities/client-field-view-activity`, config);
  return response;
};

export const setIsLogoutSent = () => async (dispatch) => {
  return dispatch(completeSentLogout());
};